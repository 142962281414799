@tailwind base;
@tailwind components;
@tailwind utilities;

.mobile-action-button {
  @apply inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

@screen sm {
  .mobile-action-button {
    @apply text-sm px-3 py-1.5;
  }
}
